
































































































import {Component, Vue} from 'vue-property-decorator';
import Location from '@/models/Location';
import {namespace} from 'vuex-class';
import User from '@/models/User';
import CleanTime from '@/models/CleanTime';
import JobManageComponent from '@/components/job/JobManage.component.vue';
import {WEEKDAYS} from '@/Constants';
import Customer from '@/models/Customer';
import {cleanTimeStoreActions} from '@/stores/cleanTime.store';
import RJTabs from '@/components/shared/custom-vuetify/RJTabs.vue';
import LocationArchiveComponent from '@/components/location/LocationArchive.component.vue';
import {TabItem} from '@/interfaces/TabItem';
import {Permission} from '@/misc/enums/permission.enum';
import QRCodeStyling from 'qr-code-styling';

const CustomerStore = namespace('customer');
const UserStore = namespace('user');
const cleanTimeStore = namespace('cleanTime');
const JobStore = namespace('job');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    LocationArchiveComponent,
    RJTabs,
    LocationCleaningDataComponent: () => import(
        /* webpackChunkName: "LocationCleaningDataComponent" */
        '@/components/location/LocationCleaningData.component.vue'),
    LocationCleanTimeOverviewComponent: () => import(
        /* webpackChunkName: "LocationCleanTimeOverviewComponent" */
        '@/components/location/LocationCleanTimeOverview.component.vue'),
    LocationManageSpecificDataComponent: () => import(
        /* webpackChunkName: "LocationManageSpecificDataComponent" */
        '@/components/location/LocationManageSpecificData.component.vue'),
    JobManageComponent: () => import(
        /* webpackChunkName: "JobManage" */
        '@/components/job/JobManage.component.vue'),
    LocationMasterData: () => import(
        /* webpackChunkName: "LocationMasterData" */
        '@/components/location/LocationMasterData.component.vue'),
    LocationManageMasterDataComponent: () => import(
        /* webpackChunkName: "LocationManageMasterDataComponent" */
        '@/components/location/LocationManageMasterData.component.vue'),
    UploadFileComponent: () => import(
        '@/components/shared/UploadFile.component.vue'),
  },
})
export default class LocationDashboardView extends Vue {
  @CustomerStore.Mutation('storeLocation')
  public storeLocationMutation!: (location: Location | undefined) => void;
  @CustomerStore.Action('loadLocationAction')
  public loadLocationAction!: (arg: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @CustomerStore.Action('deleteLocationAction')
  public deleteLocationAction!: (location: Location) => Promise<Location>;
  @UserStore.Action('loadUsersAction')
  private loadUsersAction!: (payload: { companyId: string }) => Promise<User[]>;
  @cleanTimeStore.Action(cleanTimeStoreActions.GET_CLEAN_TIME_ACTION)
  public getCleanTimeAction!: (cleanTimeId: string) => Promise<CleanTime>;
  @cleanTimeStore.Action(cleanTimeStoreActions.LOAD_CLEAN_TIMES_ACTION)
  public loadCleanTimesAction!: (locationId: string) => Promise<CleanTime[]>;
  @JobStore.Action('cancelRequestsAction')
  public cancelJobRequestsAction!: () => void;
  /**
   * Model for tabs component
   */
  public tabsModel: number = 0;
  /**
   * boolean to reset the overview inside html
   */
  public resetOverview: boolean = false;
  /**
   * boolean to show the jobDialog
   */
  public addJobDialog: boolean = false;
  /**
   * boolean to reset the state of the job creation dialog
   */
  public resetJobDialog: boolean = false;
  /**
   *
   */
  public autoInsert: boolean = false;
  /**
   * bool to prevent showing wrong data in MasterDataComponent. Otherwise, the name may be wrong
   */
  public showData: boolean = false;
  private showEditModal: boolean = false;
  private showDeleteDialog: boolean = false;
  @JobStore.Mutation('clearJobs')
  private clearJobs!: () => void;
  @JobStore.Mutation('clearCachedJobs')
  private clearCachedJobs!: () => void;
  @CustomerStore.Action('loadCustomerAction')
  private loadCustomerAction!: (customerId: string) => Promise<Customer[]>;
  @CustomerStore.Action('editLocationAction')
  private editLocationAction!: (arg: { location: Location, shouldBeStored: boolean }) => Promise<Location>;

  @CustomerStore.Getter('customer')
  public _customer!: Customer;

  public get customer() {
    return this._customer;
  }

  @CustomerStore.Getter('location')
  public _location!: Location;

  public get location() {
    return this._location;
  }

  private get locationTabs(): TabItem[] {
    return [
      {
        key: 'masterdata',
        text: this.$t('GENERAL.MASTER_DATA').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.LOCATION_READ_OWN),
      }, {
        key: 'cleantimes',
        text: this.$t('CLEANTIME_OVERVIEW.VIEWS.LIST').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.CLEANTIME_READ_OWN),
      },
      {
        key: 'documents',
        text: this.$t('CUSTOMER_DASHBOARD.DOCUMENTS').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.FILE_READ),
      },
      {
        key: 'archive',
        text: this.$t('VERSION_CONTROL.ARCHIVE').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.LOCATION_UPDATE_OWN),
      }, {
        key: 'cleaningdata',
        text: this.$t('CUSTOMER_DASHBOARD.CLEANING_DATA').toString(),
        available: this.$userRoleHandler.hasPermission(Permission.LOCATION_READ),
      },
    ];
  }

  public async closeManageComponent(updatedItem: Location) {
    if (updatedItem) {
      await this.editLocationAction({location: updatedItem, shouldBeStored: true});
    }
    this.showEditModal = false;
  }
  public showJobDialog(editMode: boolean = false, apiCleanTime?: CleanTime, insertAttributes = false): void {
    this.autoInsert = true;

    // Reset Job Dialog and show it in the end.
    this.resetJobDialog = true;
    this.addJobDialog = true;

    this.$nextTick(() => {
      this.resetJobDialog = false;

      // if job dialog should be opened in edit mode
      // Wait a dom update to tell the job manage component to do so
      if (insertAttributes) {
        this.autoInsert = true;

        // waits a dom update and inserts the existing cleanTime data to the form
        this.$nextTick(() => {
          // Call the Edit method for the cleantime
          (this.$refs.jobManageComponent as JobManageComponent).setEditCleanTime(apiCleanTime!, editMode);
        });
      }
    });
  }


  /**
   * Opens edit dialog of the cleanTime. If edit is true, edit the selected CleanTime, if edit is false,
   */
  public async onUseCleanTime(cleanTime: CleanTime, edit = true, useOrigin = false): Promise<void> {
    try {
      // get CleanTime from api
      const apiCleanTime = await this.getCleanTimeAction(useOrigin ? cleanTime.origin : cleanTime.id!);
      apiCleanTime.recurrence = this.getRecurrenceFromByWeek(apiCleanTime);

      // if by weekday is null, every day was selected
      if (apiCleanTime.byWeekDay === null) {
        apiCleanTime.byWeekDay = WEEKDAYS.map((val: string) => val);
      }

      // opens the dialog box
      this.showJobDialog(edit, apiCleanTime, true);
    } catch (e: any) {
      if (e.status === 404) {
        // Shows an error message that the cleanTime is already deleted and cannot be edited
        this.$notifyErrorSimplified('CLEANTIME_OVERVIEW.ERRORS.CLEANTIME_ALREADY_DELETED');
      } else {
        // Shows a generic error message that the cleanTime cannot be edited
        this.$notifyErrorSimplified('CLEANTIME_OVERVIEW.ERRORS.COULD_NOT_EDIT_CLEANTIME');
      }
    }
  }

  /**
   * Returns the recurrence that is stored in byWeekday
   */
  public getRecurrenceFromByWeek(cleanTime: CleanTime): string {
    let recurrence = '1';

    // Check if the frequency is not daily
    // and if the byWeek Array has a length greater then zero
    if (cleanTime!.freq! !== 'daily' && cleanTime!.byWeekDay!.length > 0) {

      // Get first byWeekDay entry
      const day = cleanTime.byWeekDay![0];

      // regex to find any numbers in our selected day
      const match = day!.match('[\\d -]+');

      // If match was found and has a length greater than zero, take the first entry
      // Else use 1
      recurrence = (match && match!.length > 0) ? match![0] : '1';
    }

    // returns the found recurrence
    return recurrence;
  }

  /**
   * Hides the Job Dialog, if abort was pressed
   */
  public hideJobDialog(): void {
    this.addJobDialog = false;
  }

  /**
   * Updates a CleanTime in our Location CleanTimes array if an edit succeeds
   */
  public updateCleanTimeEntry(cleanTime: CleanTime): void {
    // try to find the passed clean time in our location array
    const indexToUpdate = this.location.cleanTimes.findIndex((entry) => entry.id === cleanTime.id);

    // if found replace data, with the updated ones
    if (indexToUpdate > -1) {
      // Replace existing CleanTime with our updated one
      // this executes a dom reload
      this.location.cleanTimes.splice(indexToUpdate, 1, cleanTime);

      // Store our current edited location once more in our location store
      this.storeLocationMutation(this.location);
    }

    // Clear Jobs, so that the Calendar will reload its data
    this.clearJobsAndCache();
  }

  /**
   * Add CleanTime to the locations list of cleanTimes
   */
  public async addCreatedCleanTime(cleanTime: CleanTime): Promise<void> {
    // add cleanTime to location
    this.location.cleanTimes.push(cleanTime);

    // update location in state
    this.storeLocationMutation(this.location);

    // Clear Jobs, so that the Calendar will reload its data
    this.clearJobsAndCache();
  }

  /**
   * Clears all Jobs and Cache from the JobStore
   */
  public clearJobsAndCache(): void {
    // Clear Jobs, so that the Calendar will reload its data
    this.clearJobs();
    this.clearCachedJobs();
    this.cancelJobRequestsAction();
  }

  public toCustomerDashboard() {
    this.$router.push({
      name: 'customerDashboard',
      params: {
        companyId: this.$route.params.companyId,
        customerId: this.$route.params.customerId!,
      },
    });
  }

  private async onDeleteItem() {
    await this.deleteLocationAction(this.location);
    if (this.$userRoleHandler.hasPermission(Permission.CUSTOMER_READ_OWN)) {
      await this.$router.push({
        name: 'customerDashboard',
        params: {companyId: this.$route.params.companyId, customerId: this.customer.id!},
      });
    } else {
      await this.$router.push({
        name: 'locationsOverview',
        params: {companyId: this.$route.params.companyId},
      });
    }

  }

  private async created() {
    await this.initialize();
    await this.loadCleanTimesAction(this.location.id!);
  }

  private async initialize() {
    try {
      await this.loadUsersAction({companyId: this.$route.params.companyId});
      if (!this.customer && this.$userRoleHandler.hasPermission(Permission.CUSTOMER_READ_OWN)) {
        await this.loadCustomerAction(this.$route.params.customerId ?? this.location.customerId);
      }
      await this.loadLocationAction({locationId: this.$route.params.locationId, shouldBeStored: true});
      this.showData = true;
    } catch (e: any) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }
}
